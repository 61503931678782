import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import * as React from 'react';

import { ContactForm, IWidgetFormValues, Layout } from '../../components';
import { formatInfoLinks, formatMenuLinks } from '../../utils/helpers';
import {
  IAllContentfulCategory,
  IContentfulPage,
  IContentfulVacationBanner,
} from '../../utils/types';
import * as styles from '../denkmalmesse-2022/denkmalmesse-2022.module.less';

interface IFeuertrutzPageProps {
  data: {
    contentfulPage: IContentfulPage;
    allContentfulCategory: IAllContentfulCategory;
    contentfulVacationBanner: IContentfulVacationBanner;
    allContentfulPrivacyAndToS: {
      nodes: {
        title: string;
        url: string;
      }[];
    };
  };
}

const FeuertrutzPage: React.FunctionComponent<IFeuertrutzPageProps> = ({ data }) => {
  const [submitted, setSubmitted] = React.useState(false);
  const handleFinish = (values: IWidgetFormValues) => {
    fetch('/.netlify/functions/ContactForm', {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        channel: 20,
        formName: 'Contact Feuertrutz',
        gclid: Cookies.get('gclid'),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        } else {
          setSubmitted(true);
          return res;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, 'De');
  const infoLink = formatInfoLinks(data.allContentfulPrivacyAndToS.nodes, 'De');
  const customDescription = data.contentfulPage.description.description;
  const title = data.contentfulPage.title;
  return (
    <Layout
      links={menuLink}
      infoLinks={infoLink}
      vacationBanner={data.contentfulVacationBanner}
    >
      {!submitted && (
        <div className={styles.container}>
          <h1>{title}</h1>
          {customDescription && <p>{customDescription}</p>}
        </div>
      )}
      <ContactForm
        isSubmitted={submitted}
        onSubmit={handleFinish}
        showIdentityField
      />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: "De" } }
    ) {
      nodes {
        ...category
      }
    }
    contentfulPage(slug: { eq: "feuertrutz" }) {
      title
      description {
        description
      }
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: "De" } }) {
      nodes {
        url: slug
        title
      }
    }

    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default FeuertrutzPage;
